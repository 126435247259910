import platform from 'platform'

import { setItem } from './utils/indexdb'

export const AppVersion = '4.7.3'
export const OS = platform?.os?.family ?? 'unknown'
export const OSVersion = platform?.os?.version ?? 'unknown'
export const PlatformName = platform.name ?? 'unknown'

try {
	setItem('app-version', AppVersion)
	setItem('os', OS)
	setItem('os-version', OSVersion)
} catch (e) {
	console.error('did not sync to db')
}
