import Vue from 'vue'
import VueTour from 'vue-tour'
import VueGtag from 'vue-gtag'
import VueExpandableImage from 'vue-expandable-image'
import { Chart as ChartJS, registerables } from 'chart.js'
import { install } from 'resize-observer'
import '@mdi/font/css/materialdesignicons.css'

import App from '@/App'
import store from '@/store'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import Ads from 'vue-google-adsense'
import { ExceptionLog } from '@/services/ExceptionLogService.js'

/** CSS */
import 'vuetify/dist/vuetify.min.css'
// import './assets/css/nprogress.css'
import 'vue-tour/dist/vue-tour.css'

Vue.config.errorHandler = function (err, vm) {
	const currentTime = new Date().toISOString()

	// Function to extract the component name from the Vue instance
	const getComponentName = (vm) => {
		if (vm.$root === vm) return 'root'
		const name = vm._isVue
			? (vm.$options && vm.$options.name) || (vm.$options && vm.$options._componentTag)
			: vm.name
		return (
			(name ? `<${name}>` : `anonymous component`) +
			(vm._isVue && vm.$options && vm.$options.__file ? ` at ${vm.$options.__file}` : '')
		)
	}

	// Function to extract the function name from the error stack
	const getFunctionName = (err) => {
		const stackLines = err.stack.split('\n')
		if (stackLines.length > 1) {
			const functionLine = stackLines[1].trim()
			const functionName = functionLine.split(' ')[1]
			return functionName
		}
		return 'unknown function'
	}

	const ErrorLog = {
		ErrorSource: getComponentName(vm) + getFunctionName(err),
		ErrorMessage: err.message || err.toString(),
		SystemErrorTime: currentTime,
		ErrorStack: JSON.stringify(err.stack),
		Attachments: ''
	}
	const formData = new FormData()
	for (const key in ErrorLog) {
		formData.append(key, ErrorLog[key])
	}

	ExceptionLog(formData)
	console.error(ErrorLog)
}

// if dont support resize observer then install it
if (!window.ResizeObserver) install()

ChartJS.register(...registerables)
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)

Vue.config.productionTip = false
Vue.prototype.$api = process.env.VUE_APP_API_URL
Vue.prototype.$api_base = process.env.VUE_APP_API_URL_BASE
Vue.prototype.$secretKey = '@rk !nf0soft K*y'
Vue.use(VueExpandableImage)
Vue.use(
	VueGtag,
	{
		config: { id: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID },
		disableScriptLoad: process.env.NODE_ENV !== 'production', // only run on production
		appName: 'Doctor diary'
	},
	router // auto tracking page views
)

Vue.use(VueTour)

new Vue({
	render: (h) => h(App),
	router,
	store,
	vuetify
}).$mount('#app')
window.app = Vue // Expose app for Cypress
